.wrap-home-gallery {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  padding-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  * {
    font-family: "GT Walsheim Pro", sans-serif;
  }
  .home-gallery-header {
    padding-top: 155px;
    h1 {
      margin-bottom: 54px;
      // font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 115%;
      letter-spacing: 0.075em;
      color: #15131C;
    }
  }
   /* width */
   &::-webkit-scrollbar {
    width: 10px;
    height: 15px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f100;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #88888850;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #55555550;
  }
}
.mb-15 {
  margin-bottom: 15px;
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
}
.fancybox__slide {
  background: #000000;
  padding: 80px 8px 0px 8px !important;
}
.fancybox__thumbs {
  display: none;
}
.fancybox__nav {
  display: none;
}
.wrap-btn-fancy {
  position: absolute;
  z-index: 1051;
  bottom: 50px;
  right: 50px;
  display: flex;
  flex-direction: row;
  .btn-prev {
    margin-right: 23px;
  }
}
.btn-close-fancy {
  position: absolute;
  z-index: 1051;
  top: 130px;
  right: 50px;
}
.card-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.25rem;

  .card {
    display: inline-block;
    width: 100%;
  }
}

.image-mosaic {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: 240px;
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #353535;
    font-size: 3rem;
    color: #fff;
    box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    transition: all 500ms;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
  }
}

@media screen and (min-width: 600px) {
  .card-tall {
    grid-row: span 2 / auto;
  }

  .card-wide {
    grid-column: span 2 / auto;
  }
}

