.wrap-video-intro {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background: #000000;

  .btn-skip-video {
    position: absolute;
    bottom: 80px;
    left: 45px;
    font-weight: 700;
    border-radius: 0px;
    font-size: 15px;
    line-height: 20.25px;
    background: #fff;
    width: 193px;
    height: 51px;
    @media only screen and (max-width: 1366px) {
      bottom: 150px;
    }
  }
}
