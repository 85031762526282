.wrap-guide-popup {
  position: absolute;
  z-index: 1000;
  width: 70vw;
  background-color: #ffffff;
  max-height: 80vh;
  top: 15vh;
  left: 15vw;
  overflow-y: auto;
  overflow-x: hidden;

  .popup-header {
    height: 119px;
    background-color: #000030;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 57px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.075em;
      color: #FFFFFF;
      margin: 0;
    }
  }

  .popup-body {
    padding: 0px;
    .wrap-intro-description {
      background-color: #FFFFFF;
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .intro-description {
        margin-bottom: 60px;
        .title {
          font-family: 'GT Walsheim Pro';
          font-style: normal;
          font-weight: 700;
          font-size: 50px;
          line-height: 115%;
          letter-spacing: -0.01em;
          color: #000030;
        }
        .description {
          font-family: 'PT Serif';
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 57px;
          color: #000030;
          margin-top: 30px;
        }
      }
      .btn-explore {
        width: fit-content;
      }
    }
    .wrap-intro-action {
      padding: 60px;
      background-color: #000030;
      .desktop {
        .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-basis: auto;
          .icon {
            width: 150px;
            flex-shrink: 0;
            text-align: center;
          }
          .text {
            margin-left: 20px;
            color: #FFFFFF;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
          }
        }
      }
      .item {
        margin-bottom: 20px;
        &:last-child {
          margin: 0px;
        }
        span {
          margin-left: 50px;
          font-family: 'GT Walsheim Pro';
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 34px;
          color: #FFFFFF;
        }
      }
    }
    .intro-item {
      text-align: center;
    }
  }

  .close-btn {
    position: absolute;
    top: 40px;
    right: 49px;
    cursor: pointer;
  }
}

.wrap-intro-future-btn {
  position: absolute;
  z-index: 100;
  width: 200px;
  right: 40px;
  bottom: 100px;
  background-color: transparent;
  padding-right: 10px;
  text-align: right;
  border-right: 2px solid #FFFFFF;
  span {
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
  }
}
