.wrap-gallery-popup {
  position: absolute;
  z-index: 1000;
  width: 80vw;
  background-color: #ffffff;
  height: calc(100vh - 230px);
  top: 155px;
  left: 10vw;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
    pointer-events: none;
  }

  .close-btn {
    position: absolute;
    top: 43px;
    right: 49px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    .close-btn-icon {
      position: absolute;
      top: 15px;
      left: 15px;
    }
  }

  .wrap-popup-content {
    position: relative;

    .wrap-360-btn {
      position: absolute;
      bottom: 29px;
      left: 100px;
      cursor: pointer;
      button {
        background: transparent;
        color: #000030;
        font-weight: bold;
        border: 3px solid #000030;
        font-size: 16px;
        width: 212px;
        padding: 0px;
        height: 40px;

        .btn-arrow {
          width: 40px;
          border-right: 3px solid #000030;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .btn-text {
          font-family: "GT Walsheim Pro";
          color: #000030;
          font-weight: bold;
          font-size: 16px;
          text-decoration: none;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .popup-content {
    height: 100%;

    .popup-header {
      height: 125px;
      background-color: #000030;
      display: flex;
      align-items: center;
      padding-left: 100px;

      .title {
        font-family: "GT Walsheim Pro Light";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        letter-spacing: 0.075em;
        margin-bottom: 0px;
        color: #d4c1a7;
      }
    }

    .popup-body {
      position: relative;
      height: calc(100% - 125px);

      .description {
        font-family: "PT Serif";
        height: 100%;
        padding-top: 20px;
        padding-left: 25px;
        padding-right: 20px;
        padding-bottom: 20px;
        overflow-y: auto;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        .description-text {
          height: 100%;
          overflow: auto;
        }
      }

      .images {
        height: 100%;
        position: relative;

        .wrap-gallery-image-popup {
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-color: #000000;
          .slick-list {
            height: 100%;
            .slick-track {
              height: 100%;
              display: flex;
              .slick-slide > div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .image-popup-item {
                overflow: hidden;
                img {
                  object-fit: contain;
                }
              }
            }
          }
        }

        .wrap-btn-next {
          z-index: 1001;
          position: absolute;
          bottom: 40px;
          right: 49.1px;
          cursor: pointer;
        }

        .wrap-btn-prev {
          z-index: 1001;
          position: absolute;
          bottom: 40px;
          right: 112.1px;
          cursor: pointer;
        }
      }
    }
  }
}

.bg-trim {
  position: absolute;
  z-index: 1000;
  background: rgba($color: #202020, $alpha: 0.5);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}
