.wrap-virtual-gallery {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  padding-top: 155px;
  .virtual-gallery-content {
    width: 100%;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f100;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #88888850;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #55555550;
    }
  }
  .virtual-gallery-header {
    h1 {
      margin-bottom: 54px;
      font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 115%;
      letter-spacing: 0.075em;
      color: #15131C;
    }
  }
  .virtual-gallery-body {
    .virtual-item {
      h3 {
        font-family: 'GT Walsheim Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 115%;
        display: flex;
        align-items: center;
        color: #000030;
        &::after {
          margin-left: 25px;
          content: "";
          border: 1px solid #000030;
          width: 100px;
        }
      }
    }
  }
}
