.wrap-legend-sidebar {
  position: absolute;
  z-index: 900;
  top: 154px;
  left: 0;
  width: 310px;
  max-height: calc(100vh - 174px);
  overflow-y: scroll;
  background-color: transparent;
  padding: 0px;

  .card-title {
    margin: 0;
    padding: 30px;
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    padding-bottom: 27px;
    background-color: #000030;
  }

  .card-body {
    padding: 30px;
    background-color: #000030;

    .card {
      background-color: #000030;

      &.card-item {
        margin-bottom: 20px;
        .category-name {
          display: flex;
          flex-direction: row;
          align-items: center;

          .color-key {
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }
        }
      }

      .list-group {
        list-style-type: none;

        .list-group-item {
          padding: 10px 0px;

          &.heading {
            border-top: 1px solid;
            font-family: 'GT Walsheim Pro';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
            padding: 3px 0px;
            opacity: 1;
          }
          &.no-selected-item {
            color: #FFFFFF;
          }
          &.has-selected-item {
            color: #B8B5B8;
          }
          &.heading-active {
            border-top: 1px solid;
            font-family: 'GT Walsheim Pro';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
            padding: 3px 0px;
            opacity: 1;
          }
        }

        .wrap-list-item {
          list-style-type: none;
          padding: 0px;

          li {
            font-family: 'PT Serif';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 34px;
            display: flex;
            align-items: center;
            color: #FEFEFE;
            cursor: pointer;
          }
        }
      }
    }
  }

  .card-footer {
    padding: 0px;
    display: flex;
    justify-content: center;

    .list-group {
      width: 260px;
      background-color: #FFFFFF;
      border-radius: 0px;
      padding: 10px 22px;
      border-bottom: 5px solid #000030;

      .list-group-item {
        padding: 0px;
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 34px;
        display: flex;
        align-items: center;
        color: #000030;
        display: flex;
        align-items: center;
        flex-direction: row;
        .color {
          width: 15px;
          height: 15px;
          margin-right: 17px;
          &.existing-dev {
            background-color: #000030;
          }
          &.park-recreate {
            background-color: #A1B0D9;
          }
          &.future-dev {
            background-color: #F0C99E;
          }
          &.walking-tracks {
            background-color: #63E3A6;
          }
          &.mountain-bike-trails {
            background-color: #DF8988;
          }
        }
      }
    }
  }
}
