$cubeSpacing: 100px;

.wrap-top-nav {
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1051;
  background-color: #000030;
  pointer-events: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrap-logo {
    position: absolute;
    height: 54px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .logo{
    cursor: pointer;
  }

  * {
    font-size: 14px;
  }

  span {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 6px 12px;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }

  .menu-img {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    overflow: hidden;
    display: inline-block;

    img {
      width: 100%;
    }
  }

  .nav-menu {
    width: 100%;
    position: relative;
    pointer-events: auto;
    text-align: center;
    display: inline-block;
    vertical-align: top;

    &.disabled .left li,
    &.disabled .right li {
      pointer-events: none;
    }

    .left {
      text-align: right;
      width: calc(45% - #{$cubeSpacing / 2});
      transform: translateX(75%);
      z-index: 1;
      li:first-of-type {
        transform: translateX(50%);
      }

      li:last-of-type {
        transform: translateX(-50%);
      }
    }

    .nav-cube {
      width: $cubeSpacing;
      text-align: center;
      pointer-events: auto;
      z-index: 2;

      & > div {
        display: inline-block;
      }
    }

    .right {
      text-align: left;
      transform: translateX(-75%);
      width: calc(45% - #{$cubeSpacing / 2});
      z-index: 1;

      li:first-of-type {
        transform: translateX(50%);
      }
      li:last-of-type {
        transform: translateX(-50%);
      }
    }

    ul {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      transition: all 0.5s ease-in-out;
      vertical-align: middle;

      /* 3 items */
      li:first-child:nth-last-child(3),
      li:first-child:nth-last-child(3) ~ li {
        width: 38%;
      }

      /* 2 items */
      li:first-child:nth-last-child(2),
      li:first-child:nth-last-child(2) ~ li {
        width: 38%;
      }

      li {
        color: #fff;
        display: inline-block;
        list-style-type: none;
        opacity: 0;
        position: relative;
        text-align: center;
        transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
        user-select: none;
        vertical-align: middle;

        // &:not(.placeholder) span {
        //   font-weight: bold;
        //   position: relative;

        //   &::before {
        //     content: '';
        //     height: 1px;
        //     width: 0;
        //     background: #FFDB00;
        //     position: absolute;
        //     bottom: 0;
        //     opacity: 0;
        //     transition: all 0.2s;
        //   }
        // }
      }

      // li.active {
      //   span {
      //     &::before {
      //       opacity: 1;
      //       width: 97px;
      //     }
      //   }
      // }
    }
  }
}

.wrap-top-nav.show {
  .nav-menu {
    ul {
      transform: translateX(0) !important;

      li {
        opacity: 1;
        transform: translateX(0) !important;
        transition: opacity 0.5s ease-in, transform 0.5s ease-in-out;

        &:hover {
          span {
            &::before {
              width: 97px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
